import React from "react";
import { Box, Flex, Heading,Input,Text,Button,Image,ListIcon,ListItem,List,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Divider,
  } from "@chakra-ui/react";
  import { AiOutlineCheck } from 'react-icons/ai'
  // Firebase Start
  import { db } from './firebase-config'
  import { addDoc,collection } from 'firebase/firestore'
  // Firebase End
  import { Link } from 'react-router-dom';
const LP = () => {
    const [isSubscribed,setIsSubscribed] = React.useState(false)
    const [email,setEmail] = React.useState(null)
    const [notValidEmail,setNotValidEmail] = React.useState(false)
    
    const isValidEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    const saveEmail = async () => {
      try {
        if(!isValidEmail(email)) {
          setNotValidEmail(true)
          return
        }
        const collectionRef = collection(db,'sequelize_orm');
        await addDoc(collectionRef,{
          email,
          created_at : new Date()
        })
        setIsSubscribed(true)
      } catch (error) {
        console.log(error)
      }
    }
    return (
        <>
        <Flex
    w={'100vw'} minH='100vh'
    m={0}
    direction={{base:'column',md:'row'}}
    >
      <Flex minW={'50%'} bgColor='#fd841f9c' minH='100vh'
      direction={'column'}
      justifyContent='space-between'
      py={10}
      px={20}
      >
        <Box>
          <Link to='/'>
          <Image 
          src={require('./images/logo.png')}
          w={70}
          alt='BEJJA OTHMANE'
          title='BEJJA OTHMANE'
          />
          </Link>
        </Box>
       {!isSubscribed &&  <Box>
        <Heading as='h1' my={5}
        fontSize='4xl'
        fontWeight={'bold'}
        color="#3E6D9C"
        >INCREASE CODE READIBILITY WITH SEQUELIZE ORM</Heading>
        <Heading as='h2'
        fontSize='2xl'
        fontWeight={'light'}
        lineHeight="35px"
        color='#FFF'
        >
          Learn how to use the Sequelize <Text as='span' title='Object-relational mapping'>ORM</Text> in Node.js to transform complex MySQL queries into simple & readable code for <Text as='span' borderBottomWidth={2} borderBottomColor="#3E6D9C">Absolute Beginners</Text>.
        </Heading>
        <Text as="h3" mt={'10px'} fontWeight='light'>
        Subscribe and get a <Text as='span' fontSize={'2xl'} fontWeight={'bold'} color='#FFF' borderBottomWidth={2} borderBottomColor="#e14d2a">60%</Text> discount when the course is available!
        </Text>
        <Box 
        display='flex'
        my={3}
        >
          <Input 
          variant={'outline'}
          type='email'
          h={"60px"}
          w={'60%'}
          borderRadius={0}
          placeholder='Your E-mail'
          _placeholder={{ color: '#FFF' }}
          borderColor={'#FFF'}
          onChange={e=>setEmail(e.target.value)}
          onFocus={(()=>setNotValidEmail(false))}
          />
          <Button
          size='lg'
          h={"60px"}
          borderRadius={0}
          ml={1}
          bgColor='#FFF'
          onClick={()=>saveEmail()}
          >Subscribe</Button>
        </Box>
        {notValidEmail && <Text fontSize='small'>Please Enter a valid E-mail!</Text>}
        </Box>}
       {isSubscribed &&  <Box>
        <Text color='white' fontSize='4xl'
          fontWeight={'extrabold'}
          textAlign='center'
          my={4}
          >Your E-mail has been saved!</Text>
          <Text textAlign='center'>
          Thank you for your interest in this course, an E-mail with the discount coupon will be mailed to you once the course is available!
          </Text>
        </Box>}
        <Box>
        <Text
        fontSize='small'
        color="#3E6D9C"
        fontWeight={'bold'}
        >*You will receive an email with a discount link once the course is available</Text>
        <Divider />
        <Flex mt={3} gap={2}>
        <Box display='inline-block'>
        <Link to='/privacy-policy'>
          <Text fontWeight={'bold'} fontSize='small'>Privacy Policy</Text>
        </Link>
        </Box>
        <Box display='inline-block'>
        <Link to='/terms-of-service'>
          <Text fontWeight={'bold'} fontSize='small'>Terms Of Service</Text>
        </Link>
        </Box>
        <Box display='inline-block'>
        <Link to='/disclaimer'>
          <Text fontWeight={'bold'} fontSize='small'>Disclaimer</Text>
        </Link>
        </Box>
        </Flex>
        </Box>
      </Flex>
      <Box minW={'50%'} minH={{base:'140vh',md:'100vh'}}
      pos='relative'
      overflowY='hidden'
      >
        <Image 
        src='https://images.pexels.com/photos/5496463/pexels-photo-5496463.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
        pos='absolute'
        top={0}
        left={0}
        w={'100%'}
        height='100%'
        objectFit={'cover'}
        />
        <Box
        pos='absolute' 
        top='50%'
        left='50%'
        style={{
          margin:'0',
          transform: 'translate(-50%, -50%)',
        }}
        w={'70%'}
        >
       <Box 
        py={'30px'}
        px={'15px'}
        w={'100%'}
        style={{
          boxShadow: '20px 20px 50px rgba(0, 0, 0, 0.5)',
          borderRadius: '15px',
          background: 'rgba(255, 255, 255, 0.5)',
          borderTop: '1px solid rgba(255, 255, 255, 0.5)',
          borderLeft: '1px solid rgba(255, 255, 255, 0.5)',
          backdropFilter: 'blur(5px)'
        }}
        mb={4}
        >
          <Text color='white' fontSize='4xl'
          fontWeight={'extrabold'}
          textAlign='center'
          my={4}
          >What You'll Learn</Text>
          <List spacing={3}>
            <ListItem>
            <ListIcon as={AiOutlineCheck} fontSize={25} color='white' />
             <Text as='span' fontWeight={'extrabold'} color='white'>The use of Sequelize ORM with Node JS and Mysql</Text>
              </ListItem>
            <ListItem>
            <ListIcon as={AiOutlineCheck} fontSize={25}  color='white' />
              <Text as='span' fontWeight={'extrabold'} color='white'>How to perform <Text as='span' title='Create,Read,Update,Delete'>CRUD</Text> Operations</Text>
              </ListItem>
            <ListItem>
            <ListIcon as={AiOutlineCheck} fontSize={25}  color='white' />
              <Text as='span' fontWeight={'extrabold'} color='white'>Understand Sequelize Relationships and How to Use them</Text>
              </ListItem>
            <ListItem>
            <ListIcon as={AiOutlineCheck} fontSize={25}  color='white' />
              <Text as='span' fontWeight={'extrabold'} color='white'>Create a micro website application using Sequelize ORM</Text>
              </ListItem>
          </List>
        </Box>
       <Box 
        py={'30px'}
        px={'15px'}
        w={'100%'}
        style={{
          boxShadow: '20px 20px 50px rgba(0, 0, 0, 0.5)',
          borderRadius: '15px',
          background: 'rgba(255, 255, 255, 0.5)',
          borderTop: '1px solid rgba(255, 255, 255, 0.5)',
          borderLeft: '1px solid rgba(255, 255, 255, 0.5)',
          backdropFilter: 'blur(5px)'
        }}
        >
          <Text color='white' fontSize='4xl'
          fontWeight={'extrabold'}
          textAlign='center'
          my={4}
          >F.A.Q</Text>
          <Accordion allowToggle >
  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box flex='1' textAlign='left'>
        Why do we use ORM?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
    ORM stands for Object Relational Mapper and it allows us to avoid the need to write raw SQL queries by adding an object-oriented layer between relational databases and object-oriented programming languages.
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box flex='1' textAlign='left'>
        Why should I learn Sequlize ORM?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
    Sequelize is a modern and stable Node.js ORM for Oracle, Postgres, MySQL, MariaDB, SQLite, SQL Server, and more.
    </AccordionPanel>
  </AccordionItem>
  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box flex='1' textAlign='left'>
        Do I need to be an expert to follow the course?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
    Absolutely not, this course will help you to get a global view of ORMs and the necessity of using them in your next projects. I will help you understand concepts step by step then we will create together an interesting small web application to visualize the relation between your code and the Sequelize ORM.
    </AccordionPanel>
  </AccordionItem>
  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box flex='1' textAlign='left'>
        Where will the course be held, and how much will it cost?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
    The course will be available on Udemy for just 29.99$ where I will show the best practices to use this Powerful and Amazing ORM.
    </AccordionPanel>
  </AccordionItem>

</Accordion>
        </Box>
        </Box>
       
      </Box>
    </Flex>
        </>
    );
}
 
export default LP;