import { initializeApp } from "firebase/app";
import { getFirestore  } from '@firebase/firestore'

// Config Info
const firebaseConfig = {
    apiKey: "AIzaSyCNr6Kg6ZILeJ9CCUXqVHDZxjVHuUm9eok",
    authDomain: "udemy-courses-44a11.firebaseapp.com",
    projectId: "udemy-courses-44a11",
    appId: "1:169471948889:web:0d7389b9f31ecbfa64d948"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app)