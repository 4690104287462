import { Box, Button, Text } from "@chakra-ui/react";
import React from "react";
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { Link } from "react-router-dom";
const NotFound = () => {
    return (<Box
        background={'#fd841f9c'}
        minH='100vh'
        py={'60px'}
        px={{base:'10px',md:'300px'}}
        textAlign='center'
    >
        <Text fontSize={'8xl'}>404</Text>
        <Text fontSize={'4xl'}>Page Not Found</Text>
        <Button as={Link} to='/' variant={'ghost'} size='md' leftIcon={<AiOutlineArrowLeft size={20} />}>
            Go Back To Home
        </Button>
    </Box>);
}
 
export default NotFound;