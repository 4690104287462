import React from 'react'
import './App.css'
import {  Route , Switch } from 'react-router-dom'
import LP from './LandingPage';
import PP from './PrivacyPolicy';
import Disclaimer from './Disclaimer';
import TOS from './TOS';
import NotFound from './NotFound';

const App = () => {
 
  return (
    <>
    <Switch>
      <Route exact path={'/'}>
          <LP />
      </Route>
      <Route exact path='/privacy-policy'>
        <PP />
      </Route>
      <Route exact path='/disclaimer'>
        <Disclaimer />
      </Route>
      <Route exact path='/terms-of-service'>
        <TOS />
      </Route>
      <Route exact path='*'>
        <NotFound />
      </Route>
    </Switch>
    </>
  );
}
 
export default App;