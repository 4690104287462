import React from "react";
import ReactDOM from "react-dom";
// import "./index.css";
import App from "./App";
import reportWebVitals from './reportWebVitals';
import { ChakraProvider,extendTheme  } from '@chakra-ui/react'
import { BrowserRouter as Router } from 'react-router-dom'
ReactDOM.render(
  <ChakraProvider>
    <Router>
     <App />
     </Router>
  </ChakraProvider>,
  document.getElementById('root')
);

reportWebVitals();
